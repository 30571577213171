@import "@material/typography/mdc-typography";

code,
pre {
  background-color: #fafafa;
  border-radius: 3px;
}
code {
  font-size: 85%;
  padding: 0.2em 0.4em;
}
pre code {
  padding: 0;
}
pre {
  padding: 16px;
  overflow: auto;
  line-height: 1.45;
  font-family: monospace;
}
.copy-to-clipboard {
  cursor: pointer;
}

.cname-details {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  pre {
    width: 100%;
  }
}

.invalid-domain {
  --mdc-theme-primary: #ca1515;
}

.card-documentation-icon {
  svg {
    height: 18px;
    fill: #313131;
  }
}

.pre-terms {
  white-space: break-spaces;
  margin-right: 16px;
}

.warning {
  color: #ffa600;
}
